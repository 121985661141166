import axios from "axios";

const host = `https://gmac-staging.talview.com`

const axiosInstance = axios.create({
    baseURL: host,
    headers: {
        'Content-Type': 'application/json',
    }
});

axiosInstance.interceptors.request.use(
    async (config) => {
        let userSession = JSON.parse(localStorage.getItem("nv-user-session"));
        if (userSession && userSession.token) {
            config.headers.Authorization = `Bearer ${userSession.token}`
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export const getPaymentLink = async (couponCode, updatedPrice) => {
    const { data } = await axiosInstance.get(`/payment/url`, {
      params: {
        coupon: couponCode,
        price: updatedPrice
      }
    });
    if (!data) throw new Error("Error");
    return data;
  };
  

export const scheduleLater = async (payload = {}) => {
    const { data } = await axiosInstance.post(`/booking/book-later`, payload);
    if (!data) throw new Error("Error");
    return data;
  };
  

export const getSlots = async (start, end) => {
    const options = {
        params: {
            start_time: `${start}`,
            end_time: `${end}`,
            duration: 3600,
            exact_match: true
        }
    }
    const { data } = await axiosInstance.get(`/booking/availability`, options)
    if (!data) throw new Error("Error");
    return data;
}

export const bookSlot = async (payload = {}) => {
    const { data } = await axiosInstance.post(`/booking`, payload);
    if (!data) throw new Error("Error");
    return data;
}

export const validateCoupon = async (payload = {}) => {
  const { data } = await axiosInstance.post('/coupon/validate', payload);
  if (!data) throw new Error("Error");
  return data;
};

  
export const getPaymentValidation = async (payload = {}) => {
  const { data } = await axiosInstance.get('/payment/validate');
  if (!data) throw new Error("Error");
  return data;
};



export const getBookingHistory = async () => {
  const { data } = await axiosInstance.get('/booking/history');
  if (!data) throw new Error("Error");
  return data;
};


export const getReschedule = async () => {
  const { data } = await axiosInstance.get('/reschedule');
  if (!data) throw new Error("Error");
  return data;
};


  