import { useState, useEffect } from "react";
import Calendar from "react-calendar";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Index";
import { getSlots, bookSlot } from "../../utils/index";
import "../../../src/index.css";

const Schedule = () => {
  const navigate = useNavigate();

  const currentDate = new Date();
  const initialDate = new Date();
  initialDate.setDate(currentDate.getDate());

  const [date, setDate] = useState(initialDate);
  const [selectedTimeObj, setSelectedTimeObj] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const changingDate = moment(date).format("DD-MM-YYYY");
  let newStart = moment(date).toISOString();
  let newStartIST = moment.utc(newStart).local().format();
  const [loading, setLoading] = useState(true);

  let newEnd = moment(date).add(1, "days").toISOString();
  let newEndIST = moment.utc(newEnd).local().format();

  useEffect(() => {
    setLoading(true);
    let result;
    getSlots(newStart, newEnd)
      .then((data) => {
        result = data.filter((item) => {
          let starting = moment(item.starts_at).format("DD-MM-YYYY");
          return starting === changingDate;
        });
        setSelectedTimeObj(result);
        setLoading(false);
      })
      .catch((e) => {
        setSelectedTimeObj([]);
        setLoading(false);
        toast.error("Slot unavailable");
      });
  }, [date]);

  const handleClick = (items) => {
    setSelectedSlot(items);
    getSelectedTimeId(items);
  };

  let eventId, slotId;
  const getSelectedTimeId = (obj) => {
    eventId = obj && obj.event_id;
    slotId = obj && obj.id;
  };

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const handleConfirm = () => {
    if (selectedSlot) {
      const eventId = selectedSlot.event_id;
      const slotId = selectedSlot.id;
      const payload = JSON.stringify({
        event_id: eventId,
        slot_id: slotId,
        timezone: timeZone,
      });

      setLoading(true);
      bookSlot(payload)
        .then(() => {
          toast.success("Slot booked successfully!");
          navigate(`/scheduled`);
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          toast.error("Something went wrong: " + e.response.data.message);
        });
    } else {
      toast.error("Please select a slot before confirming");
    }
  };
  return (
    <div className="sm:container md:max-w-4xl mx-auto absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-55%] rounded-2xl shadow-lg p-10 border-gray-100 border-[1px]">
      <div className="flex align-middle">
        <Calendar
          onChange={setDate}
          value={date}
          className="flex-1 shadow-lg"
          minDate={initialDate}
        />
        <div
          id="slots"
          className="flex-[0.3] h-full p-4 shadow-lg border-[1px] border-gray-100 flex flex-col gap-2"
        >
          <p className="font-bold text-gray-500">{date.toDateString()}</p>
          <div
            id="slotsContainer"
            className="flex flex-col h-[252px] overflow-y-scroll"
          >
            {loading ? (
              <p className="m-auto">Loading...</p>
            ) : selectedTimeObj.length ? (
              selectedTimeObj?.map((items) => (
                <Button
                  key={items.id}
                  colorpicker="blue"
                  onClick={() => handleClick(items)}
                  content={moment(items.starts_at).format("hh:mm A")}
                  active={selectedSlot && items.id === selectedSlot.id}
                />
              ))
            ) : (
              <p className="m-auto">No slots available</p>
            )}
          </div>
          <Button
            colorpicker="confirm"
            content="Confirm"
            onClick={handleConfirm}
            isLoading={loading}
          />
        </div>
      </div>
      <p className="weekends">Slots can be booked on weekends as well.</p>
    </div>
  );
};

export default Schedule;
