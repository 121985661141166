import React, { useState, useEffect } from "react";
import { getBookingHistory, getReschedule } from "../../utils";
import Button from "../../components/Button/Index";
import "../../../src/index.css";
import moment from 'moment';
import 'moment-timezone';

const Reschedule = () => {
  const [state, setState] = useState({
    examName: "",
    examSlot: "",
    examEndTime: "",
    checkboxChecked: false,
    errorMessage: "",
    loading: true,
  });

  const formatDateToLocalTime = (dateString) => {
    const date = moment(dateString);
    const formattedDate = date.format('MMMM D, YYYY [at] hh:mm A');
    const timeZoneAbbr = moment.tz(date, moment.tz.guess()).format('z');
    return `${formattedDate} ${timeZoneAbbr}`;
  };

  const handleApiError = (error) => {
    if (error.response?.status === 422 && error.response?.data?.message.startsWith("Candidate can only reschedule")) {
      setState(prevState => ({ 
        ...prevState, 
        errorMessage: (
          <span>
            Dear Candidate, please note that you have reached the maximum number of allowed reschedules for your certification exam. 
            We kindly request you to attend the exam as per the current schedule. If you have any concerns, please reach out to our <a href="https://talview.freshdesk.com/support/tickets/new" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>support team</a>. 
            Thank you for your understanding.
          </span>) }));
    } else {
      setState(prevState => ({ ...prevState, errorMessage: error.response?.data?.message || "Error occurred" }));
    }
  };

  const fetchBookingHistory = () => {
    getBookingHistory()
      .then(response => {
        if (response) {
          const { exam_name, slot_start_time, slot_end_time } = response;
          setState(prevState => ({
            ...prevState,
            examName: exam_name,
            examSlot: formatDateToLocalTime(slot_start_time),
            examEndTime: formatDateToLocalTime(slot_end_time),
          }));
        }
      })
      .catch(handleApiError)
      .finally(() => {
        setState(prevState => ({ ...prevState, loading: false }));
      });
  };

  const handleCheckboxChange = () => {
    setState(prevState => ({ ...prevState, checkboxChecked: !prevState.checkboxChecked }));
  };

  const handleRescheduleButtonClick = () => {
    getReschedule()
      .then(response => {
        if (response && response.reschedule_url) {
          window.location.href = response.reschedule_url;
        }
      })
      .catch(handleApiError);
  };


  useEffect(() => {
    fetchBookingHistory();
  }, []);

  const { examName, examSlot, examEndTime, checkboxChecked, errorMessage, loading } = state;

  return (
    <>
      <div className="card">
        <p className="mt-6">
          <b>Disclaimer: </b>You can only book a time slot 48 hours in advance, and if you want to reschedule for a different date, it must not be the same as your existing booking. If you choose to continue, your current booking will be deleted. 
        </p>
        <table className="table-container">
        <tbody>
          <tr className="border">
            <th>Exam Name</th>
            <th>Exam Start Time</th>
            <th>Exam End Time</th>
          </tr>
          {loading ? (
            <tr>
              <td colSpan="3" className="text-center">
                Loading...
              </td>
            </tr>
          ) : (
            <tr>
              <td id="exam-name">{examName}</td>
              <td id="exam-slot">{examSlot}</td>
              <td id="exam-end-time">{examEndTime}</td>
            </tr>
          )}
        </tbody>
      </table>

        {errorMessage ? (
          <div className="error-message" style={{ color: "red" }}>
            {errorMessage}
          </div>
        ) : (
          <>
            <div className="ui checkbox checkbox-container">
              <input
                type="checkbox"
                id="confirm-checkbox"
                name="confirm_reshedule"
                value="Yes"
                onChange={handleCheckboxChange}
                checked={checkboxChecked}
              ></input>
              <label htmlFor="confirm-checkbox" className="checkbox-label pl-2">
                I understand by clicking on the Reschedule button, the above-mentioned
                slot will be cancelled.
              </label>
            </div>
            
            <div className="button-container mb-6">
              <Button
                colorpicker="confirm"
                onClick={handleRescheduleButtonClick}
                content="Reschedule"
                disabled={!checkboxChecked}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Reschedule;
