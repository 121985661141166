import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button/Index";
import { getPaymentLink, validateCoupon, getPaymentValidation } from "../../utils/index";
import { AuthContext } from '../../containers/Store/Index';

const Pay = ({ discountedCost, setDiscountedCost }) => {
  
  const [checked, setChecked] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');
  const [isCouponValidated, setIsCouponValidated] = useState(false);
  const [validatedCoupon, setValidatedCoupon] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const userSession = useContext(AuthContext);


  const handleChange = () => {
    setChecked(!checked);
  };

  const handleCouponCodeChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/\s+/g, '').toUpperCase();
    setCouponCode(sanitizedValue);
  
    
    if (isCouponValidated) {
      setIsCouponValidated(false);
      setDiscountedCost(userSession.cost);
      setCouponError('');
    }
  };
  
  // const userSession = useContext(AuthContext);
  const { cost } = userSession;

  const handleValidateCoupon = () => {
    if (couponCode === '') {
      setCouponError('Please add a valid coupon code');
      setDiscountedCost(userSession.cost); 
      return;
    }
  
    validateCoupon({ coupon: couponCode, price: cost })
      .then(data => {
        setIsCouponValidated(true);
        setValidatedCoupon(couponCode); // Store the successfully validated coupon code
        setCouponError(<span style={{ color: '#78B900' }}>Coupon code applied!</span>);
        setDiscountedCost(data.price);
      })
      .catch(error => {
        let errorMessage = "Error validating coupon";
        if (error.response && error.response.data) {
          errorMessage = "Invaid coupon code";
        }
        setIsCouponValidated(false);
        setValidatedCoupon(''); // Reset validatedCoupon if an error occurs
        setCouponError(errorMessage);
        setDiscountedCost(userSession.cost); // Reset discountedCost if an error occurs
      });
  
  };
  

  
  
  
  useEffect(() => {
    const handleUnload = () => {
      userSession.clearCouponFromUserSession();
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const handleClick = async () => {
    try {
      setIsLoading(true); // Set loading to true before API request 
      const updatedPrice = userSession.cost;
      const validCoupon = isCouponValidated ? validatedCoupon : ''; // Use validatedCoupon here
      const paymentData = await getPaymentLink(validCoupon, updatedPrice);
      if (paymentData.payment_url) {
        window.location.replace(paymentData.payment_url);
      } else {
        throw new Error("Payment link not available");
      }
    } catch (error) {
      console.error("Error fetching payment link:", error);
      toast.error("Error fetching payment link. Please try again."); // Display error using toast
    } finally {
      setIsLoading(false); // Set loading to false after API request is complete
    }
  };
  

  
  return (
    <div className="text-sm">
      <div className="pl-4 pt-2 rounded">
        <input
          id="couponCode"
          type="text"
          value={couponCode}
          onChange={handleCouponCodeChange}
          className="p-2 w-72 rounded border border-gray-400 focus:border-blue-600 outline-none focus:outline-none"
          placeholder="Have a coupon code?"
        />
       
       <Button
      colorpicker="payment"
      content={"Validate"}
      onClick={handleValidateCoupon}
      className="rounded-r"
      style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
    />
  </div>
  {couponError && (
    <div className="text-red-500 mb-4 pl-6">
      {couponError}
    </div>
  )}
  <div className="pb-8 px-5">
    <input
      id="privacyPolicy"
      type="checkbox"
      checked={checked}
      onChange={handleChange}
      className="mr-2"
    />
    <label htmlFor="privacyPolicy" className="cursor-pointer">
      I have read and understood the Talview T&C `T&C hyper link here` before attending a Talview remotely Proctored Exam.
    </label>
  </div>
  <div className="flex justify-between align-middle">
  <Button
          colorpicker="payment"
          disabled={!checked || isLoading}
          content="Click to proceed"
          onClick={handleClick}
          isLoading={isLoading}
        />
    <div className="text-right pr-6">
      <p>Not the right exam?</p>
      <p><a target="_blank" rel="noreferrer" className="text-blue-600" href="https://talview.freshdesk.com/support/tickets/new"> Contact Support</a></p>
    </div>
  </div>
</div>
  
  
  )
};
export default Pay;
