import { FaSpinner } from 'react-icons/fa';

function Button(props) {
  const { colorpicker, onClick, content, disabled, isLoading, active } = props;

  const bgColors = {
    disabled: "opacity-90",
    confirm: "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-[auto] focus:ring-blue-300",
    blue: `hover:bg-blue-800 text-white mb-2 font-bold py-2 px-4 rounded focus:ring-blue-800 ${active ? "bg-black-500" : "bg-blue-500"}`,
    btn: "text-white bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br dark:focus:ring-4 focus:outline-none focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2",
    payment: "text-white bg-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-block mb-4 ml-4 mt-1.5"
  };

  const handleClick = () => {
    if (typeof onClick === "function") onClick();
  }

  return (
    <button
      className={`${bgColors[colorpicker]} ${active ? "bg-black" : ""}`}
      onClick={handleClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <span className="flex items-center">
          <FaSpinner className="animate-spin mr-2" />
          Loading
        </span>
      ) : (
        content
      )}
    </button>
  );
}

export default Button;
